<template>
	<section id="home" class="py-5" style="background-color: #690808">
		<v-container fluid>
			<v-row class="justify-space-around">
				<v-col lg="5">
					<LoginForm color="transparent" :dark="true" />
				</v-col>
				<v-col lg="5" class="my-auto" style="color: white">
					<div>
						<h3>Sube y descarga apuntes, encuentra información de tus asignaturas, resuelve tus dudas.</h3>
						<br />
						<p>OverSOS es la evolución de SOS ETSEIB, nace de nuestra visión de expansión hacia otras universidades.</p>
						<p>La plataforma ahora permite conectar con empresas y ayudarte a despegar tu carrera profesional, además de tu carrera universitaria.</p>
					</div>
					<br />
					<div class="d-flex align-center">
						<div class="text-uppercase text-white">¿eres empresa? Ve a</div>
						<v-btn color="primary" rounded class="ml-3" href="https://enterprise.oversos.com" target="_blank">
							<span>ENTERPRISE.OVERSOS.COM</span>
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'LandingHome',
	components: {
		LoginForm: () => import('@/components/auth/LoginForm.vue'),
	},
}
</script>
